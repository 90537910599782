import React from "react";
import clinic from "../../Asset/osr.png";


const BannerHomeImage = () => {
  return (
    <div>
      <div>
        <img className="visionimage" src={clinic} alt="AboutImage" />
      </div>
  
    </div>
  );
};

export default BannerHomeImage;
